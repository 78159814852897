import React from 'react'
import { Helmet } from 'react-helmet'
import HandsOnHelpContent from 'componentsNew/HandsOnHelp'
import LayoutNew from 'componentsNew/LayoutNew'

const phone = '866-430-8512'

const HandsOnHelp = ({ location }) => {
  return (
    <LayoutNew location={location} includeCitySearch>
      <Helmet>
        <title>
          Request Free Research Help to Navigate the Medicare Maze | Medicare Companion
        </title>
      </Helmet>
      <HandsOnHelpContent phone={phone} formPortal={2} />
    </LayoutNew>
  )
}

export default HandsOnHelp
